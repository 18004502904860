import {
  call,
  put,
  takeLatest,
  select,
  all,
  takeEvery,
} from "redux-saga/effects";
import { apolloClient } from "../../apollo-client";
import attendeeSummaryQuery from "../../graphql/bsl/gql-custom/queries/attendeesSummary.gql";
import addAttendeeMutation from "../../graphql/bsl/gql-custom/mutations/addUpdateGuest.gql";
import deleteAttendeeMutation from "../../graphql/bsl/gql-custom/mutations/deleteGuest.gql";
import { loginSelector, bookingPersistedStateSelector } from "../selectors";
import {
  AddUpdateGuestResponseUnion,
  AttendeesSummaryResponseUnion,
  DeleteGuestResponseUnion,
  MutationAddUpdateGuestArgs,
  MutationDeleteGuestArgs,
  QueryAttendeesSummaryArgs,
} from "@generated/types";
import {
  getAttendeeSummary,
  getAttendeeSummaryFail,
  getAttendeeSummarySuccess,
  addAttendee,
  addAttendeeFail,
  addAttendeeSuccess,
  removeAttendee,
  removeAttendeeFail,
  removeAttendeeSuccess,
  setCabinReservationId,
} from "../slices";

function* getAttendeeSummarySaga(
  action: ReturnType<typeof getAttendeeSummary>
) {
  const { data } = yield select(loginSelector);
  const token = data?.token;
  if (!!token) {
    const attendeesSummaryCall = () =>
      apolloClient.query<
        { attendeesSummary: AttendeesSummaryResponseUnion },
        QueryAttendeesSummaryArgs
      >({
        query: attendeeSummaryQuery,
        variables: action.payload,
        ...{
          context: {
            headers: {
              authorization: `Bearer ${token}`,
            },
          },
        },
        fetchPolicy: "no-cache",
      });

    try {
      const response = yield call(attendeesSummaryCall);
      const { attendeesSummary } = response.data!;

      switch (attendeesSummary.__typename) {
        case "AttendeesSummaryResponse":
          if (attendeesSummary?.attendeesSummary) {
            yield put(
              getAttendeeSummarySuccess(attendeesSummary?.attendeesSummary)
            );
            if (
              attendeesSummary?.attendeesSummary?.cabinsInfo &&
              attendeesSummary?.attendeesSummary?.cabinsInfo[0]
            ) {
              yield put(
                setCabinReservationId(
                  attendeesSummary?.attendeesSummary?.cabinsInfo[0]
                    .cabinReservationId
                )
              );
            }
          }
          break;
        case "SystemError":
          console.log("ConfirmedBookingSummary non valid entity error");
          throw new Error(attendeesSummary?.message || "");
        default:
          break;
      }
    } catch (error: any) {
      yield put(getAttendeeSummaryFail(error.message));
      // appInsights.trackException(error);
    }
  } else {
    const error: any = "Unable to get Attendee Summary due to missing token.";
    yield put(getAttendeeSummaryFail(error));
    // appInsights.trackException(error);
  }
}

function* addAttendeeSaga(action: ReturnType<typeof addAttendee>) {
  const { data } = yield select(loginSelector);
  const token = data?.token;
  if (!!token) {
    const addAttendeeCall = () =>
      apolloClient.mutate<
        { addUpdateGuest: AddUpdateGuestResponseUnion },
        MutationAddUpdateGuestArgs
      >({
        mutation: addAttendeeMutation,
        variables: action.payload,
        context: {
          headers: {
            authorization: `Bearer ${token}`,
          },
        },
      });

    try {
      const response = yield call(addAttendeeCall);

      switch (response.data?.addUpdateGuest?.__typename) {
        case "Guest": {
          yield put(addAttendeeSuccess(response.data.addUpdateGuest));
          break;
        }
        case "AddUpdateGuestError": {
          throw Error(
            response.data.addUpdateGuest?.message || "Failed to add attendee"
          );
          break;
        }
        default:
          break;
      }
    } catch (error: any) {
      yield put(addAttendeeFail(error.message));
      // appInsights.trackException(error);
    }
  } else {
    const error: any = "Unable to Add Attendee due to missing token.";
    yield put(addAttendeeFail(error));
    // appInsights.trackException(error);
  }
}

function* removeAttendeeSaga(action: ReturnType<typeof removeAttendee>) {
  const { data } = yield select(loginSelector);
  const token = data?.token;
  if (!!token) {
    const removeAttendeeCall = () =>
      apolloClient.mutate<
        { deleteGuest: DeleteGuestResponseUnion },
        MutationDeleteGuestArgs
      >({
        mutation: deleteAttendeeMutation,
        variables: {
          guest: action.payload,
        },
        context: {
          headers: {
            authorization: `Bearer ${token}`,
          },
        },
      });

    try {
      const response = yield call(removeAttendeeCall);
      const { deleteGuest } = response.data!;

      switch (deleteGuest?.__typename) {
        case "DeleteGuestResponse": {
          yield put(removeAttendeeSuccess(deleteGuest));
          break;
        }
        case "DeleteGuestError": {
          throw Error(deleteGuest?.message || "Failed to remove attendee");
          break;
        }
        default:
          break;
      }
    } catch (error: any) {
      yield put(removeAttendeeFail(error.message));
      // appInsights.trackException(error);
    }
  } else {
    const error: any = "Unable to Remove Attendee due to missing token.";
    yield put(removeAttendeeFail(error));
    // appInsights.trackException(error);
  }
}

function* reFetchAttendeeSummarySaga(
  action:
    | ReturnType<typeof addAttendeeSuccess>
    | ReturnType<typeof removeAttendeeSuccess>
) {
  const { confirmedBookingId } = yield select(bookingPersistedStateSelector);
  yield put(
    getAttendeeSummary({
      bookingId: confirmedBookingId,
    })
  );
}

export default function* attendeeSagas() {
  yield all([
    takeLatest("attendeeSummary/getAttendeeSummary", getAttendeeSummarySaga),
    takeEvery("attendeeAdd/addAttendee", addAttendeeSaga),
    takeEvery("attendeeRemove/removeAttendee", removeAttendeeSaga),
    takeLatest("attendeeAdd/addAttendeeSuccess", reFetchAttendeeSummarySaga),
    takeLatest(
      "attendeeRemove/removeAttendeeSuccess",
      reFetchAttendeeSummarySaga
    ),
    takeLatest("vehicleAdd/addVehicleSuccess", reFetchAttendeeSummarySaga),
    takeLatest(
      "vehicleRemove/removeVehicleSuccess",
      reFetchAttendeeSummarySaga
    ),
  ]);
}

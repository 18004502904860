import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { apolloClient } from "../../apollo-client";
import tokenisedCardsQuery from "../../graphql/bsl/gql-custom/queries/tokenisedCards.gql";
import scheduledPaymentsQuery from "../../graphql/bsl/gql-custom/queries/scheduledPayments.gql";
import changeCardForUpcomingPayments from "../../graphql/bsl/gql-custom/mutations/changeCardForUpcomingPayments.gql";
import removeSavedCard from "../../graphql/bsl/gql-custom/mutations/removeSavedCard.gql";
import createAddCard from "../../graphql/bsl/gql-custom/mutations/addSavedCard.gql";
import {
  ChangeCardForUpcomingPaymentsResponse,
  MutationAddSavedCardArgs,
  MutationChangeCardForUpcomingPaymentsArgs,
  MutationRemoveSavedCardArgs,
  QueryScheduledPaymentsArgs,
  QueryTokenisedCardsArgs,
  RemoveSavedCardResponse,
  ScheduledPaymentsResponse,
  TokenisedCardsResponse,
} from "@generated/types";
import {
  createAddCardFail,
  createAddCardSuccess,
  createAddCard as createAddCardAction,
  getRemoveCardsSuccess,
  getRemoveCardsFail,
  getRemoveCardsAction,
  getScheduledPaymentAction,
  getScheduledPaymentsFail,
  getScheduledPaymentsSuccess,
  changeCardForUpcomingPaymentsAction,
  changeCardForUpcomingPaymentsFailAction,
  changeCardForUpcomingPaymentsSuccessAction,
  getPaymentCardsAction,
  getPaymentCardsFail,
  getPaymentCardsSuccess,
} from "../slices";
import { getErrorMessageByKey } from "../../components/Utils";
import { loginSelector, errorMessagesDataSelector } from "../selectors";

function* getPaymentCards(action: ReturnType<typeof getPaymentCardsAction>) {
  const getPaymentCardsCall = () =>
    apolloClient.query<
      { tokenisedCards: TokenisedCardsResponse },
      QueryTokenisedCardsArgs
    >({
      query: tokenisedCardsQuery,
      variables: action.payload,
      fetchPolicy: "no-cache",
    });
  try {
    const response = yield call(getPaymentCardsCall);
    if (response.data?.tokenisedCards) {
      yield put(getPaymentCardsSuccess(response.data.tokenisedCards));
    } else {
      throw Error("Failed to get payment cards");
    }
  } catch (error: any) {
    const errorMessages = yield select(errorMessagesDataSelector);
    const errorByKey = getErrorMessageByKey(error.message, errorMessages);
    yield put(getPaymentCardsFail(errorByKey?.message || error.message));
    // appInsights.trackException(error);
  }
}

function* createAddCardSaga(action: ReturnType<typeof createAddCardAction>) {
  const createAddCardCall = () =>
    apolloClient.mutate<
      { addSavedCard: PaymentResponse },
      MutationAddSavedCardArgs
    >({
      mutation: createAddCard,
      variables: {
        addSavedCardRequest: action.payload,
      },
      fetchPolicy: "no-cache",
    });

  try {
    const response = yield call(createAddCardCall);
    const { addSavedCard } = response.data!;

    if (
      addSavedCard?.success &&
      (addSavedCard?.status === "OK" || addSavedCard?.status === "3DAUTH")
    ) {
      yield put(createAddCardSuccess(addSavedCard));
    } else {
      throw Error(
        addSavedCard?.statusDetail ||
          addSavedCard?.nonValidReason ||
          "Payment Failed"
      );
    }
  } catch (error: any) {
    yield put(createAddCardFail(error.message));
  }
}

function* removeCard(action: ReturnType<typeof getRemoveCardsAction>) {
  const removeSavedCardCall = () =>
    apolloClient.mutate<
      { removeSavedCard: RemoveSavedCardResponse },
      MutationRemoveSavedCardArgs
    >({
      mutation: removeSavedCard,
      variables: action.payload,
      fetchPolicy: "no-cache",
    });

  try {
    const response = yield call(removeSavedCardCall);
    if (response.data?.removeSavedCard) {
      yield put(getRemoveCardsSuccess(response.data.removeSavedCard));
    } else {
      throw Error("Failed to remove saved card");
    }
  } catch (error: any) {
    const errorMessages = yield select(errorMessagesDataSelector);
    const errorByKey = getErrorMessageByKey(error.message, errorMessages);
    yield put(getRemoveCardsFail(errorByKey?.message || error.message));
    // appInsights.trackException(error);
  }
}

function* reFetchCardsSaga(
  action: ReturnType<
    typeof getRemoveCardsSuccess | typeof getPaymentCardsAction
  >
) {
  const { data: customerDetails } = yield select(loginSelector);
  yield put(
    getPaymentCardsAction({
      customerId: customerDetails?.customer?.id,
    })
  );
}

function* getScheduledPayments(
  action: ReturnType<typeof getScheduledPaymentAction>
) {
  const getScheduledPaymentsCall = () =>
    apolloClient.query<
      { scheduledPayments: ScheduledPaymentsResponse },
      QueryScheduledPaymentsArgs
    >({
      query: scheduledPaymentsQuery,
      variables: action.payload,
      fetchPolicy: "no-cache",
    });
  try {
    const response = yield call(getScheduledPaymentsCall);
    if (response.data?.scheduledPayments) {
      yield put(getScheduledPaymentsSuccess(response.data.scheduledPayments));
    } else {
      throw Error("Failed to get scheduled payments");
    }
  } catch (error: any) {
    const errorMessages = yield select(errorMessagesDataSelector);
    const errorByKey = getErrorMessageByKey(error.message, errorMessages);
    yield put(getScheduledPaymentsFail(errorByKey?.message || error.message));
    // appInsights.trackException(error);
  }
}

function* changePaymentCard(
  action: ReturnType<typeof changeCardForUpcomingPaymentsAction>
) {
  const { data } = yield select(loginSelector);
  const token = data?.token;
  if (!!token) {
    const changePaymentCardCall = () =>
      apolloClient.mutate<
        {
          changeCardForUpcomingPayments: ChangeCardForUpcomingPaymentsResponse;
        },
        MutationChangeCardForUpcomingPaymentsArgs
      >({
        mutation: changeCardForUpcomingPayments,
        variables: action.payload,
        context: {
          headers: {
            authorization: `Bearer ${token}`,
          },
        },
        fetchPolicy: "no-cache",
      });
    try {
      const response = yield call(changePaymentCardCall);
      if (response.data?.changeCardForUpcomingPayments) {
        yield put(
          changeCardForUpcomingPaymentsSuccessAction(
            response.data.changeCardForUpcomingPayments
          )
        );

        if (response.data?.changeCardForUpcomingPayments.success) {
          const { data: customerDetails } = yield select(loginSelector);
          const customerId = customerDetails?.customer.id;
          yield put(
            getScheduledPaymentAction({
              customerId,
            })
          );
        }
      } else {
        throw Error("Failed to change the card");
      }
    } catch (error: any) {
      const errorMessages = yield select(errorMessagesDataSelector);
      const errorByKey = getErrorMessageByKey(error.message, errorMessages);
      yield put(
        changeCardForUpcomingPaymentsFailAction(
          errorByKey?.message || error.message
        )
      );
      // appInsights.trackException(error);
    }
  } else {
    const error: any = "Unable to update card due to missing token.";
    yield put(changeCardForUpcomingPaymentsFailAction(error));
    // appInsights.trackException(error);
  }
}

export default function* ManagePaymentsSagas() {
  yield all([
    takeLatest("paymentCards/getPaymentCardsAction", getPaymentCards),
    takeEvery("addCard/createAddCard", createAddCardSaga),
    takeLatest("removedCards/getRemoveCardsAction", removeCard),
    takeLatest("removedCards/getRemoveCardsSuccess", reFetchCardsSaga),
    takeLatest(
      "scheduledPayments/getScheduledPaymentAction",
      getScheduledPayments
    ),
    takeEvery(
      "changeCard/changeCardForUpcomingPaymentsAction",
      changePaymentCard
    ),
  ]);
}

import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { apolloClient } from "../../apollo-client";
import {
  MutationApplyPromoCodeArgs,
  PromoCodeResponseUnion,
  QueryIsValidPromoCodeArgs,
  ValidatePromoCodeType,
} from "@generated/types";
import {
  checkPromoCodeFail,
  checkPromoCodeSuccess,
  setDiscountCode,
  applyPromoCodeSuccess,
  applyPromoCodeFail,
  removePromoCodeFail,
  removePromoCodeSuccess,
  resetCheckPromoCode,
  checkPromoCode,
  setPromoCode,
  getRefreshBookingSummaryAction,
  getBookingConfirmationAction,
  applyPromoCode as applyPromoCodeSlice,
  removePromoCode as removePromoCodeSlice,
  getConfirmedBookingSummaryAction,
} from "../slices";
import { bookingPersistedStateSelector } from "../selectors";
import checkPromoCodeQuery from "../../graphql/bsl/gql-generated/dot-gql/queries/isValidPromoCode.gql";
import applyPromoCodeMutation from "../../graphql/bsl/gql-generated/dot-gql/mutations/applyPromoCode.gql";
import removePromoCodeMutation from "../../graphql/bsl/gql-generated/dot-gql/mutations/removePromoCode.gql";

function* checkPromoCodeSaga(action: ReturnType<typeof checkPromoCode>) {
  const checkPromoCodeCall = () =>
    apolloClient.query<
      { isValidPromoCode: ValidatePromoCodeType },
      QueryIsValidPromoCodeArgs
    >({
      query: checkPromoCodeQuery,
      variables: {
        request: action.payload,
      },
      fetchPolicy: "no-cache",
    });

  try {
    const response = yield call(checkPromoCodeCall);
    const isValidPromoCode = response.data?.isValidPromoCode;

    if (isValidPromoCode?.isValid) {
      yield put(checkPromoCodeSuccess(isValidPromoCode));

      if (isValidPromoCode?.discountCode) {
        yield put(setPromoCode(isValidPromoCode.discountCode));
        yield put(setDiscountCode(isValidPromoCode?.discountCode));
      }
    } else {
      throw Error("Invalid Promo Code");
    }
  } catch (error) {
    yield put(checkPromoCodeFail(error.message));
    // appInsights.trackException(error);
  }
}

function* resetCheckPromoCodeSaga(
  action: ReturnType<typeof resetCheckPromoCode>
) {
  yield put(setPromoCode(null));
}

function* applyPromoCode(action: ReturnType<typeof applyPromoCodeSlice>) {
  const applyPromoCodeCall = () =>
    apolloClient.mutate<
      { applyPromoCode: PromoCodeResponseUnion },
      MutationApplyPromoCodeArgs
    >({
      mutation: applyPromoCodeMutation,
      variables: action.payload,
      fetchPolicy: "no-cache",
    });

  try {
    const response = yield call(applyPromoCodeCall);
    const { data } = response;

    switch (data?.applyPromoCode.__typename) {
      case "PromoCodeResponse":
        if (!!action.payload.promoCode) {
          yield put(
            applyPromoCodeSuccess({
              discountCode: action.payload.promoCode,
              description:
                data?.applyPromoCode.promoCodeDescription || undefined,
            })
          );
        }
        break;
      case "SystemError":
        yield put(
          applyPromoCodeFail(
            data.applyPromoCode.message || "Failed to apply promo code."
          )
        );
        break;
      default:
        break;
    }
  } catch (e) {
    yield put(applyPromoCodeFail(e.message));
  }
}

function* applyPromoCodeSuccessSaga() {
  const { bookingId, cabinReservationId } = yield select(
    bookingPersistedStateSelector
  );

  yield put(
    getRefreshBookingSummaryAction({
      reservationId: cabinReservationId,
      isPostBookingJourney: false,
    })
  );

  yield put(
    getBookingConfirmationAction({
      bookingId,
    })
  );
}

function* removePromoCode(action: ReturnType<typeof removePromoCodeSlice>) {
  const removePromoCodeCall = () =>
    apolloClient.mutate<
      { removePromoCode: PromoCodeResponseUnion },
      MutationApplyPromoCodeArgs
    >({
      mutation: removePromoCodeMutation,
      variables: action.payload.props,
      fetchPolicy: "no-cache",
    });

  try {
    const response = yield call(removePromoCodeCall);
    const { data } = response;

    switch (data?.removePromoCode.__typename) {
      case "PromoCodeResponse":
        yield put(removePromoCodeSuccess(action.payload.isPostBooking));
        break;
      case "SystemError":
        yield put(
          removePromoCodeFail(
            data.removePromoCode.message || "Failed to apply promo code."
          )
        );
        break;
      default:
        break;
    }
  } catch (e) {
    yield put(removePromoCodeFail(e.message));
  }
}

function* removePromoCodeSuccessSaga(
  action: ReturnType<typeof removePromoCodeSuccess>
) {
  const {
    bookingId,
    cabinReservationId,
    confirmedBookingId,
    confirmedCabinReservationId,
  } = yield select(bookingPersistedStateSelector);

  if (Boolean(action.payload)) {
    // post booking
    yield all([
      put(resetCheckPromoCode()),
      put(
        getConfirmedBookingSummaryAction({
          bookingId: confirmedBookingId,
          refreshObtainingQuoteExtras: true,
        })
      ),
    ]);
  } else {
    // pre booking
    yield all([
      put(resetCheckPromoCode()),
      put(
        getRefreshBookingSummaryAction({
          reservationId: cabinReservationId,
          isPostBookingJourney: false,
        })
      ),
      put(
        getBookingConfirmationAction({
          bookingId,
        })
      ),
    ]);
  }
}

export default function* promoCodeSagas() {
  yield all([
    takeLatest("promoCode/checkPromoCode", checkPromoCodeSaga),
    takeLatest("promoCode/resetCheckPromoCode", resetCheckPromoCodeSaga),
    takeEvery("promoCode/applyPromoCode", applyPromoCode),
    takeLatest("promoCode/applyPromoCodeSuccess", applyPromoCodeSuccessSaga),
    takeEvery("promoCode/removePromoCode", removePromoCode),
    takeLatest("promoCode/removePromoCodeSuccess", removePromoCodeSuccessSaga),
  ]);
}

import { call, put, takeEvery, all } from "redux-saga/effects";
import { apolloClient } from "../../apollo-client";
import {
  BookingSummary_S,
  BookingSummaryResponseUnion,
  MutationPopulateAbandonedBasketBookingArgs,
} from "@generated/types";
import abandonedBasketMutation from "../../graphql/bsl/gql-custom/mutations/populateAbandonedBasketBooking.gql";
import {
  abandonedBasket,
  abandonedBasketFail,
  abandonedBasketSuccess,
  addCabinSuccess,
} from "../slices";

function* createAbandonedBasketSaga(
  action: ReturnType<typeof abandonedBasket>
) {
  const abandonedBasketCall = () =>
    apolloClient.mutate<
      { populateAbandonedBasketBooking: BookingSummaryResponseUnion },
      MutationPopulateAbandonedBasketBookingArgs
    >({
      mutation: abandonedBasketMutation,
      variables: action.payload,
    });

  try {
    const response = yield call(abandonedBasketCall);
    const data = response.data?.populateAbandonedBasketBooking;
    switch (data?.__typename) {
      case "BookingSummaryResponse": {
        yield put(
          abandonedBasketSuccess(data?.bookingSummary as BookingSummary_S)
        );
        yield put(addCabinSuccess(data?.bookingSummary as BookingSummary_S));
        break;
      }
      case "SystemError": {
        yield put(abandonedBasketFail(data?.message || ""));
        break;
      }
      default:
        break;
    }
  } catch (error: any) {
    yield put(abandonedBasketFail(error.message));
    // appInsights.trackException(error);
  }
}

export function* abandonedBasketSagas() {
  yield all([
    takeEvery("abandonedBasket/abandonedBasket", createAbandonedBasketSaga),
  ]);
}

import moment from "moment";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { apolloClient } from "../../apollo-client";
import {
  CalendarAvailabilityType,
  QueryCalendarAvailabilityArgs,
} from "@generated/types";
import {
  getAvailableDatesFail,
  getAvailableDatesSuccess,
  getAvailableDates,
} from "../slices";
import datesQuery from "../../graphql/bsl/gql-generated/dot-gql/queries/calendarAvailability.gql";

function* getAvailableDatesSaga(action: ReturnType<typeof getAvailableDates>) {
  const datesCall = () =>
    apolloClient.query<
      { calendarAvailability: CalendarAvailabilityType[] },
      QueryCalendarAvailabilityArgs
    >({
      query: datesQuery,
      variables: {
        request: action.payload,
      },
      fetchPolicy: "no-cache",
    });

  try {
    const response = yield call(datesCall);
    const { calendarAvailability } = response.data!;

    if (calendarAvailability) {
      const calendarAvailabilityData = calendarAvailability.map((calendar) => {
        const startDate = moment(calendar.startDate).utc().format("YYYY-MM-DD");
        const endDate = moment(calendar.endDate).utc().format("YYYY-MM-DD");
        return {
          startDate,
          endDate,
          cabinCount: calendar.cabinCount,
          __typename: calendar.__typename,
        };
      });
      yield put(getAvailableDatesSuccess(calendarAvailabilityData));
    } else {
      throw Error("Failed to get available dates");
    }
  } catch (error: any) {
    yield put(getAvailableDatesFail(error.message));
    // appInsights.trackException(error);
  }
}

export default function* dateSagas() {
  yield all([takeLatest("dates/getAvailableDates", getAvailableDatesSaga)]);
}

import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { apolloClient } from "../../apollo-client";
import {
  MutationUpdateCustomerDetailsArgs,
  UpdateCustomerDetailResponseUnion,
} from "@generated/types";
import { loginSelector } from "../selectors";
import {
  createUpdateMydetails,
  createUpdateMydetailsFail,
  createUpdateMydetailsSuccess,
} from "../slices";
import updateCustomerDetailsMutation from "../../graphql/bsl/gql-generated/dot-gql/mutations/updateCustomerDetails.gql";

function* createUpdateDetailsSaga(
  action: ReturnType<typeof createUpdateMydetails>
) {
  const { data } = yield select(loginSelector);
  const token = data?.token;
  if (!!token) {
    const createUpdateDetailsCall = () =>
      apolloClient.mutate<
        { updateCustomerDetails: UpdateCustomerDetailResponseUnion },
        MutationUpdateCustomerDetailsArgs
      >({
        mutation: updateCustomerDetailsMutation,
        variables: action.payload,
        context: {
          headers: {
            authorization: `Bearer ${token}`,
          },
        },
        fetchPolicy: "no-cache",
      });

    try {
      const response = yield call(createUpdateDetailsCall);
      const data = response.data?.updateCustomerDetails;
      switch (data?.__typename) {
        case "UpdateCustomerDetailResponse": {
          yield put(createUpdateMydetailsSuccess(data));
          break;
        }
        case "UpdateCustomerDetailError": {
          yield put(createUpdateMydetailsFail(data?.message));
          break;
        }
        default:
          break;
      }
    } catch (error: any) {
      yield put(createUpdateMydetailsFail(error.message));
      // appInsights.trackException(error);
    }
  } else {
    const error: any =
      "Unable to Create or Update My Details due to missing token.";
    yield put(createUpdateMydetailsFail(error));
    // appInsights.trackException(error);
  }
}

export default function* updateDetailsSagas() {
  yield all([
    takeEvery(
      "updateMyDetailsCreate/createUpdateMydetails",
      createUpdateDetailsSaga
    ),
  ]);
}

import { createSlice } from "@reduxjs/toolkit";
import {
  CabinEssentialPanel_S as CabinEssentialPanel,
  Extra,
} from "@generated/types";
import type { PayloadAction } from "@reduxjs/toolkit";

export type BookingPersistState = {
  cabinReservationId: string;
  bookingId: string;
  confirmedBookingId: string;
  confirmedCabinReservationId: string;
  essentialExtras: Array<CabinEssentialPanel & Extra>;
};

const initialState: BookingPersistState = Object.freeze({
  bookingId: "",
  cabinReservationId: "",
  confirmedBookingId: "",
  confirmedCabinReservationId: "",
  essentialExtras: [],
});

export const persistBookingSlice = createSlice({
  name: "persistBooking",
  initialState,
  reducers: {
    setCabinReservationId: (state, action: PayloadAction<string>) => {
      state.cabinReservationId = action.payload;
    },
    setBookingId: (state, action: PayloadAction<string>) => {
      state.bookingId = action.payload;
    },
    setEssentialExtras: (
      state,
      action: PayloadAction<CabinEssentialPanel[]>
    ) => {
      state.essentialExtras = mapEssentialExtras(action.payload);
    },
    resetBooking: () => ({
      ...initialState,
    }),
    setConfirmedBookingId: (state, action: PayloadAction<string>) => {
      state.confirmedBookingId = action.payload;
    },
    setConfirmedCabinReservationId: (state, action: PayloadAction<string>) => {
      state.confirmedCabinReservationId = action.payload;
    },
  },
});

export const mapEssentialExtras = (
  cabinEssentialPanels: CabinEssentialPanel[]
) => {
  if (!cabinEssentialPanels.length) return [];

  return cabinEssentialPanels.reduce((obj: any, panel: any) => {
    const extra = (panel?.essentialExtras || [])[0];
    return {
      ...obj,
      [(extra?.extraCode || "").replace(
        /AAOTRUM|BGOTRUM|BWDOTRUM|CRPOTRUM|DELOTRUM|DPOTRUM|FODOTRUM|KELOTRUM|STROTRUM|SWDOTRUM|THPOTRUM/,
        "ICE"
      )]: { ...extra, ...panel },
    };
  }, {});
};

export const {
  setCabinReservationId,
  setBookingId,
  setEssentialExtras,
  resetBooking,
  setConfirmedBookingId,
  setConfirmedCabinReservationId,
} = persistBookingSlice.actions;

export default persistBookingSlice.reducer;

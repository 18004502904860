import { all, call, put, takeLatest } from "redux-saga/effects";
import { apolloClient } from "../../apollo-client";
import {
  getBlogsFailAction,
  getBlogsSuccessAction,
  getBlogsAction,
} from "../slices";
import getBlogPostsQuery from "../../graphql/bsl/gql-generated/dot-gql/queries/blogPosts.gql";
import { QueryBlogPostsArgs, BlogPostsResponse } from "@generated/types";

function* getBlogsSaga(action: ReturnType<typeof getBlogsAction>) {
  const getBlogsCall = () =>
    apolloClient.query<{ blogPosts: BlogPostsResponse }, QueryBlogPostsArgs>({
      query: getBlogPostsQuery,
      variables: action.payload,
      fetchPolicy: "no-cache",
      context: {
        timeout: 120000,
      },
    });

  try {
    const getBlogsResponse = yield call(getBlogsCall);
    const { data } = getBlogsResponse;
    switch (data?.blogPosts.__typename) {
      case "BlogPostsResponse":
        yield put(getBlogsSuccessAction(data?.blogPosts));
        break;
      default:
        break;
    }
  } catch (error: any) {
    yield put(getBlogsFailAction(error?.message || "Failed to get Blogs."));
  }
}

export function* blogSagas() {
  yield all([takeLatest("blogs/getBlogsAction", getBlogsSaga)]);
}

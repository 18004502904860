import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { apolloClient } from "../../apollo-client";
import {
  FutureBookingsResponse,
  QueryFutureBookingsArgs,
  QuerySingleFutureCabinReservationArgs,
  SingleFutureCabinReservationResponse,
} from "@generated/types";
import { loginSelector, bookingPersistedStateSelector } from "../selectors";
import {
  getSingleFutureCabinReservation,
  getSingleFutureCabinReservationSuccess,
  getConfirmedBookingSummaryAction,
  getConfirmedBookingCabinEssentialsAction,
  setConfirmedBookingId,
  getFutureBookings,
  getFutureBookingsFail,
  getFutureBookingsSuccess,
} from "../slices";
import getFutureBookingsQuery from "../../graphql/bsl/gql-generated/dot-gql/queries/futureBookings.gql";
import getSingleFutureCabinReservationQuery from "../../graphql/bsl/gql-generated/dot-gql/queries/singleFutureCabinReservation.gql";

function* getFutureBookingsSaga(action: ReturnType<typeof getFutureBookings>) {
  const getBookingsCall = () =>
    apolloClient.query<
      { futureBookings: FutureBookingsResponse },
      QueryFutureBookingsArgs
    >({
      query: getFutureBookingsQuery,
      variables: action.payload,
      fetchPolicy: "no-cache",
    });

  try {
    const getBookingsResponse = yield call(getBookingsCall);
    const { data } = getBookingsResponse;

    switch (data?.futureBookings.__typename) {
      case "FutureBookingsResponse":
        yield put(getFutureBookingsSuccess(data?.futureBookings));
        break;
      default:
        break;
    }
  } catch (error: any) {
    yield put(
      getFutureBookingsFail(error?.message || "Failed to get bookings.")
    );
  }
}

function* getSingleFutureCabinReservationSaga(
  action: ReturnType<typeof getSingleFutureCabinReservation>
) {
  const { data } = yield select(loginSelector);
  const token = data?.token;
  if (!!token) {
    const getSingleFutureCabinReservationCall = () =>
      apolloClient.query<
        { singleFutureCabinReservation: SingleFutureCabinReservationResponse },
        QuerySingleFutureCabinReservationArgs
      >({
        query: getSingleFutureCabinReservationQuery,
        variables: action.payload,
        context: {
          headers: {
            authorization: `Bearer ${token}`,
          },
        },
        fetchPolicy: "no-cache",
      });
    try {
      const getSingleFutureCabinReservationResponse = yield call(
        getSingleFutureCabinReservationCall
      );
      const { confirmedCabinReservationId } = yield select(
        bookingPersistedStateSelector
      );
      const { data } = getSingleFutureCabinReservationResponse;
      if (data?.singleFutureCabinReservation?.__typename) {
        switch (data?.singleFutureCabinReservation?.__typename) {
          case "SingleFutureCabinReservationResponse":
            yield put(
              getConfirmedBookingSummaryAction({
                bookingId: {
                  bookingId: data?.singleFutureCabinReservation.bookingId,
                },
                refreshObtainingQuoteExtras: true,
              })
            );
            yield put(
              getSingleFutureCabinReservationSuccess(
                data?.singleFutureCabinReservation
              )
            );
            if (!confirmedCabinReservationId)
              yield put(
                getConfirmedBookingCabinEssentialsAction({
                  reservationId:
                    data?.singleFutureCabinReservation.cabinReservationId,
                  isPostBookingJourney: true,
                })
              );
            yield put(
              setConfirmedBookingId(
                data?.singleFutureCabinReservation.bookingId
              )
            );
            break;
          default:
            break;
        }
      } else {
        yield put(
          getSingleFutureCabinReservationSuccess({
            bookingId: null,
            cabinReservationId: null,
          })
        );
      }
    } catch (error: any) {
      yield put(
        getFutureBookingsFail(error?.message || "Failed to get bookings.")
      );
    }
  } else {
    const error: any =
      "Unable to get Single Future Cabin Reservation due to missing token.";
    yield put(getFutureBookingsFail(error));
  }
}
export default function* futureBookingsSagas() {
  yield all([
    takeLatest("futureBookings/getFutureBookings", getFutureBookingsSaga),
    takeLatest(
      "singleFutureBooking/getSingleFutureCabinReservation",
      getSingleFutureCabinReservationSaga
    ),
  ]);
}

import { all, call, put, takeLatest } from "redux-saga/effects";
import { apolloClient } from "../../apollo-client";
import { getErrorMessagesFail, getErrorMessagesSuccess } from "../slices";
import { ErrorMessage, ErrorMessagesResponse } from "@generated/types";
import { cacheForHours, getParsedCachedItem } from "../../components/Utils";
import { CacheKeys } from "../../constants/cacheKeys";
import getErrorMessagesQuery from "../../graphql/bsl/gql-generated/dot-gql/queries/errorMessages.gql";

function* getErrorMessagesSaga() {
  const cachedErrorMessages = getParsedCachedItem(CacheKeys.ErrorMessages);

  if (
    !!cachedErrorMessages?.timestamp &&
    new Date(cachedErrorMessages.timestamp) > new Date()
  ) {
    yield put(
      getErrorMessagesSuccess(
        cachedErrorMessages?.data as Required<ErrorMessage[]>
      )
    );
    return;
  }

  const getErrorMessagesCall = () =>
    apolloClient.query<{ errorMessages: ErrorMessagesResponse }>({
      query: getErrorMessagesQuery,
      fetchPolicy: "no-cache",
    });

  try {
    const getErrorMessagesResponse = yield call(getErrorMessagesCall);
    const { data } = getErrorMessagesResponse;
    const messages = data?.errorMessages?.errorMessages;

    switch (data?.errorMessages.__typename) {
      case "ErrorMessagesResponse":
        yield put(
          getErrorMessagesSuccess(messages as Required<ErrorMessage[]>)
        );
        // todo: Get cache time from response once EPI and BSL work is done
        cacheForHours(CacheKeys.ErrorMessages, messages, 1);
        break;
    }
  } catch (error) {
    yield put(
      getErrorMessagesFail(error?.message || "Failed to get error messages")
    );
    // appInsights.trackException(error);
  }
}

export default function* errorMessagesSagas() {
  yield all([
    takeLatest("errorMessages/getErrorMessages", getErrorMessagesSaga),
  ]);
}

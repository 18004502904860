import { createSlice } from "@reduxjs/toolkit";
import { Nullable } from "../../utils";
import {
  BookingConfirmation_S as BookingConfirmation,
  BookingRemovalResponse,
  BookingSummary_S as BookingSummary,
  MutationRemoveUnconfirmedBookingArgs,
  QueryBookingConfirmationArgs,
  ReservationExtra,
} from "@generated/types";
import { selectedExtraOptions } from "../../ExtraUtils";
import { Booking } from "../../interfaces/bookingSummary";
import { SelectedExtraOption } from "../../interfaces/extras";
import type { PayloadAction } from "@reduxjs/toolkit";

export type BookingSummaryState = {
  errorMessage: Nullable<string>;
  hasError: boolean;
  loaded: boolean;
  loading: boolean;
  confirmation: Nullable<BookingConfirmation>;
  bookingSummary: Nullable<Booking>;
  selectedCabinReservationId?: string;
  getConfirmationRequest?: QueryBookingConfirmationArgs;
  cabin: Nullable<BookingSummary>;
  reservationExtraOptionIds: string[];
  petQuantity: number;
  selectedExtraOptions: SelectedExtraOption[];
  selectedPaymentOption?: Nullable<string>;
};

const initialState: BookingSummaryState = Object.freeze({
  errorMessage: null,
  hasError: false,
  loaded: false,
  loading: false,
  confirmation: null,
  bookingSummary: null,
  cabin: null,
  reservationExtraOptionIds: [],
  petQuantity: 0,
  selectedExtraOptions: [],
  selectedPaymentOption: null,
});

export const bookingSummary = createSlice({
  name: "bookingSummary",
  initialState,
  reducers: {
    getBookingConfirmationAction: (
      state,
      action: PayloadAction<QueryBookingConfirmationArgs>
    ) => {
      state.getConfirmationRequest = action.payload;
      state.loading = true;
    },
    getBookingConfirmationSuccessAction: (
      state,
      action: PayloadAction<BookingConfirmation>
    ) => {
      const cabin = action?.payload?.cabinReservations?.[0] as BookingSummary;
      state.confirmation = action.payload;
      state.cabin = cabin;
      state.reservationExtraOptionIds = makeCabinReservationOptionIds(
        (cabin?.reservationExtras || []) as ReservationExtra[]
      );
      state.petQuantity = cabin?.noOfPetsBooked || 0;
      state.loaded = true;
      state.loading = false;
      state.selectedExtraOptions = selectedExtraOptions(
        (cabin?.reservationExtras || []) as ReservationExtra[]
      );
    },
    getBookingConfirmationFailAction: (
      state,
      action: PayloadAction<string>
    ) => {
      state.hasError = true;
      state.errorMessage =
        action?.payload || "An error occurred. Please try again.";
      state.loaded = false;
      state.loading = false;
      state.cabin = null;
      state.confirmation = null;
      state.bookingSummary = null;
      state.reservationExtraOptionIds = [];
      state.petQuantity = 0;
      state.selectedExtraOptions = [];
      state.selectedPaymentOption = null;
    },
    setSelectedCabinReservationId: (state, action: PayloadAction<string>) => {
      state.selectedCabinReservationId = action.payload;
    },
    setSelectedPaymentOption: (state, action: PayloadAction<string>) => {
      state.selectedPaymentOption = action.payload;
    },
    resetBookingSummary: () => ({
      ...initialState,
    }),
    setBookingSummary: (state, action: PayloadAction<Booking>) => {
      state.hasError = false;
      state.errorMessage = null;
      state.bookingSummary = action.payload;
    },
    removeUnconfirmedBooking: (
      state,
      action: PayloadAction<MutationRemoveUnconfirmedBookingArgs>
    ) => {
      /* no state updates. handled by sagas */
    },
    removeUnconfirmedBookingSuccess: (
      state,
      action: PayloadAction<BookingRemovalResponse>
    ) => {
      /* no state updates. handled by sagas */
    },
    removeUnconfirmedBookingFailed: (state, action: PayloadAction<string>) => {
      /* no state updates. handled by sagas */
    },
  },
});

const makeCabinReservationOptionIds = (extras: ReservationExtra[]): string[] =>
  extras
    ?.flatMap((extra) => extra?.reservationExtraOptions)
    .map((option) => option?.extraOptionId)
    .filter((id: string) => id);

export const {
  getBookingConfirmationAction,
  getBookingConfirmationSuccessAction,
  getBookingConfirmationFailAction,
  setSelectedCabinReservationId,
  setSelectedPaymentOption,
  resetBookingSummary,
  setBookingSummary,
  removeUnconfirmedBooking,
  removeUnconfirmedBookingSuccess,
  removeUnconfirmedBookingFailed,
} = bookingSummary.actions;

export default bookingSummary.reducer;

import { call, put, takeLatest, all, select } from "redux-saga/effects";
import { apolloClient } from "../../apollo-client";
import {
  BookingDataForGaResponse,
  QueryBookingDataForGaArgs,
} from "@generated/types";
import { GA4Transaction } from "../../interfaces/booking";
import {
  getBookingDataForGAAction,
  getBookingDataForGAFailAction,
  getBookingDataForGASuccessAction,
} from "../slices";
import getBookingDataForGAQuery from "../../graphql/bsl/gql-generated/dot-gql/queries/bookingDataForGA.gql";
import { bookingDataForGASelector, loginSelector } from "../selectors";
import TagManager from "react-gtm-module";
import { getHashHex } from "src/utils/common";

function* getBookingDataForGASaga(
  action: ReturnType<typeof getBookingDataForGAAction>
) {
  const getBookingDataForGACall = () =>
    apolloClient.query<
      { bookingDataForGA: BookingDataForGaResponse },
      QueryBookingDataForGaArgs
    >({
      query: getBookingDataForGAQuery,
      variables: action.payload,
      fetchPolicy: "no-cache",
    });

  try {
    const getBookingDataForGAResponse = yield call(getBookingDataForGACall);
    const { data } = getBookingDataForGAResponse;
    switch (data?.bookingDataForGA.__typename) {
      case "BookingDataForGAResponse":
        yield put(getBookingDataForGASuccessAction(data?.bookingDataForGA));
        break;
      default:
        break;
    }
  } catch (error) {
    yield put(
      getBookingDataForGAFailAction(
        error?.message || "Failed to get booking data for GA."
      )
    );
    // appInsights.trackException(error);
  }
}

function* getBookingDataForGASuccessSaga(
  action: ReturnType<typeof getBookingDataForGASuccessAction>
) {
  const { loaded, bookingDataForGA } = yield select(bookingDataForGASelector);
  const { data: loginData } = yield select(loginSelector);
  const { customer } = loginData;
  const { forenames, surname, email } = customer;

  const getHashedForename = async () => await getHashHex(forenames);
  const hashedForenames: { hashedMessage: string } = yield call(
    getHashedForename
  );

  const getHashedSurname = async () => await getHashHex(surname);
  const hashedSurname: { hashedMessage: string } = yield call(getHashedSurname);

  const getHashedEmail = async () => await getHashHex(email);
  const hashedEmail: { hashedMessage: string } = yield call(getHashedEmail);

  if (loaded) {
    if (bookingDataForGA?.booking) {
      // GTM4 - Transaction
      const ga4Transaction = {
        event: "purchase",
        ecommerce: {
          transaction_id: bookingDataForGA.booking.bookingReference,
          value: bookingDataForGA.booking.revenue,
          currency: "GBP",
          coupon: bookingDataForGA?.booking?.coupon?.toUpperCase() || "",
          items: [],
        } as GA4Transaction,
        first_name: hashedForenames || "",
        last_name: hashedSurname || "",
        email_address: hashedEmail || "",
      };

      if (bookingDataForGA?.cabins) {
        bookingDataForGA.cabins.map((cabin, index) => {
          if (cabin) {
            // GTM4 - Cabin product(s)
            ga4Transaction?.ecommerce?.items.push({
              item_id: cabin.name || "",
              item_name: cabin.sku || "",
              item_category: cabin.category || "",
              price: cabin.price,
              quantity: cabin.quantity ?? 0,
              index,
            });
          }
        });
      }

      if (bookingDataForGA?.extras) {
        bookingDataForGA.extras.map((extra, index) => {
          if (extra) {
            // GTM4 - Extra product(s)
            ga4Transaction?.ecommerce?.items.push({
              item_id: extra.name || "",
              item_name: extra.sku || "",
              item_category: extra.category || "",
              price:
                extra.quantity > 1 ? extra.price / extra.quantity : extra.price,
              quantity: extra.quantity ?? 0,
              index,
            });
          }
        });
      }

      // Push Transaction to Data Layer for GTM
      TagManager.dataLayer({
        dataLayer: ga4Transaction,
      });
    }
  }
}

export default function* bookingDataForGASagas() {
  yield all([
    takeLatest(
      "bookingDataForGA/getBookingDataForGAAction",
      getBookingDataForGASaga
    ),
    takeLatest(
      "bookingDataForGA/getBookingDataForGASuccessAction",
      getBookingDataForGASuccessSaga
    ),
  ]);
}

import { all, call, put, takeEvery } from "redux-saga/effects";
import { apolloClient } from "../../apollo-client";
import {
  AddCustomerToGiftVoucherBasketResponse,
  MutationAddCustomerToGiftVoucherBasketArgs,
} from "@generated/types";
import {
  addCustomerToGvBasket,
  addCustomerToGvBasketFail,
  addCustomerToGvBasketSuccess,
} from "../slices";
import addCustomerToGiftVoucherBasket from "../../graphql/bsl/gql-generated/dot-gql/mutations/addCustomerToGiftVoucherBasket.gql";

function* addCustomerToGvBasketSaga(
  action: ReturnType<typeof addCustomerToGvBasket>
) {
  const addCustomerToGvBasketCall = () =>
    apolloClient.mutate<
      {
        addCustomerToGiftVoucherBasket: AddCustomerToGiftVoucherBasketResponse;
      },
      MutationAddCustomerToGiftVoucherBasketArgs
    >({
      mutation: addCustomerToGiftVoucherBasket,
      variables: action.payload,
    });

  try {
    const response = yield call(addCustomerToGvBasketCall);
    const success = response.data?.addCustomerToGiftVoucherBasket.success;
    if (success) {
      yield put(addCustomerToGvBasketSuccess(success));
    } else {
      throw Error("Failed to add customer to GV Basket");
    }
  } catch (error: any) {
    yield put(addCustomerToGvBasketFail(error.message));
    // appInsights.trackException(error);
  }
}

export default function* gvCustomerSagas() {
  yield all([
    takeEvery("gvCustomer/addCustomerToGvBasket", addCustomerToGvBasketSaga),
  ]);
}

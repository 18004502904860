import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { apolloClient } from "../../apollo-client";
import { getErrorMessageByKey } from "../../components/Utils";
import {
  MutationRemoveSavedCardArgs,
  RemoveSavedCardResponse,
} from "@generated/types";
import removeSavedCard from "../../graphql/bsl/gql-custom/mutations/removeSavedCard.gql";
import { loginSelector, errorMessagesDataSelector } from "../selectors";
import {
  getRemoveCardsSuccess,
  getRemoveCardsFail,
  getRemoveCardsAction,
  getPaymentCardsAction,
} from "../slices";

function* removeCard(action: ReturnType<typeof getRemoveCardsAction>) {
  const removeSavedCardCall = () =>
    apolloClient.mutate<
      { removeSavedCard: RemoveSavedCardResponse },
      MutationRemoveSavedCardArgs
    >({
      mutation: removeSavedCard,
      variables: action.payload,
      fetchPolicy: "no-cache",
    });

  try {
    const response = yield call(removeSavedCardCall);
    if (response.data?.removeSavedCard) {
      yield put(getRemoveCardsSuccess(response.data.removeSavedCard));
    } else {
      throw Error("Failed to remove saved card");
    }
  } catch (error: any) {
    const errorMessages = yield select(errorMessagesDataSelector);
    const errorByKey = getErrorMessageByKey(error.message, errorMessages);
    yield put(getRemoveCardsFail(errorByKey?.message || error.message));
    // appInsights.trackException(error);
  }
}

function* reFetchCardsSaga(
  action: ReturnType<
    typeof getPaymentCardsAction | typeof getRemoveCardsSuccess
  >
) {
  const { data: customerDetails } = yield select(loginSelector);
  yield put(
    getPaymentCardsAction({
      customerId: customerDetails?.customer?.id,
    })
  );
}

export default function* RemoveCardsSagas() {
  yield all([
    takeLatest("removedCards/getRemoveCardsAction", removeCard),
    takeLatest("removedCards/getRemoveCardsSuccess", reFetchCardsSaga),
  ]);
}

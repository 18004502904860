import { createSlice } from "@reduxjs/toolkit";
import { AddExtraRequestInput, AddExtraResponse } from "@generated/types";
import { Nullable } from "../../utils";
import type { PayloadAction } from "@reduxjs/toolkit";

export type AddExtraState = {
  errorMessage: Nullable<string>;
  hasError: boolean;
  loaded: boolean;
  loading: boolean;
  data: Nullable<AddExtraResponse[]>;
  addExtraRequest?: AddExtraRequestInput[];
  isPostBookingJourney?: boolean | null;
};

const initialState: AddExtraState = Object.freeze({
  errorMessage: null,
  hasError: false,
  loaded: false,
  loading: false,
  data: null,
  isPostBookingJourney: false,
});

export const extraAddSlice = createSlice({
  name: "extraAdd",
  initialState,
  reducers: {
    addExtra: (
      state,
      action: PayloadAction<{
        addExtraInput: AddExtraRequestInput[];
        showSticky: boolean | null;
        isPostBookingJourney: boolean | null;
      }>
    ) => {
      state.addExtraRequest = action.payload.addExtraInput;
      state.loading = true;
      state.isPostBookingJourney = action.payload.isPostBookingJourney;
      state.loaded = false;
      state.errorMessage = null;
      state.data = null;
      state.errorMessage = null;
    },
    addExtraSuccess: (
      state,
      action: PayloadAction<{
        addExtraResponse: AddExtraResponse[];
        isPostBookingJourney: boolean;
      }>
    ) => ({
      ...initialState,
      addExtraRequest: state.addExtraRequest,
      data: action.payload.addExtraResponse,
      loaded: true,
      isPostBookingJourney: action.payload.isPostBookingJourney,
    }),
    addExtraFail: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.errorMessage = action?.payload;
      state.loaded = false;
      state.loading = false;
      state.data = null;
      state.isPostBookingJourney = false;
    },
    addExtraResetState: (state, action) => ({
      ...initialState,
    }),
  },
});

export const { addExtra, addExtraSuccess, addExtraFail, addExtraResetState } =
  extraAddSlice.actions;

export default extraAddSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

const initialState = null;
export const initializeCabinSlice = createSlice({
  name: "initialize",
  initialState,
  reducers: {
    initializeAddCabin: (state, action: PayloadAction<void>) => {
      state;
    },
  },
});

export const { initializeAddCabin } = initializeCabinSlice.actions;

import { all, call, put, takeLatest } from "redux-saga/effects";
import { apolloClient } from "../../apollo-client";
import { getFaqFailAction, getFaqSuccessAction } from "../slices";
import { FaQsResponseUnion } from "@generated/types";
import getFaqQuery from "../../graphql/bsl/gql-generated/dot-gql/queries/fAQs.gql";

function* getFaqSaga(action: ReturnType<typeof getFaqSuccessAction>) {
  const getFaqFetchCall = () =>
    apolloClient.query<{ bookableLocations: FaQsResponseUnion }>({
      query: getFaqQuery,
      variables: action.payload,
      fetchPolicy: "no-cache",
    });

  try {
    const getFaqResponse = yield call(getFaqFetchCall);
    const { data } = getFaqResponse;
    if (data.fAQs?.fAQCategories?.length) {
      yield put(getFaqSuccessAction(data?.fAQs));
    } else {
      yield put(getFaqFailAction("Failed to get FAQs."));
    }
  } catch (error: any) {
    yield put(getFaqFailAction(error?.message || "Failed to get FAQs."));
  }
}

export function* faqSagas() {
  yield all([takeLatest("faqs/getFaqAction", getFaqSaga)]);
}

import moment from "moment";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { apolloClient } from "../../apollo-client";
import { AvailableYear } from "@generated/types";
import {
  getCalendarSummary,
  getCalendarSummarySuccess,
  getCalendarSummaryFail,
} from "../slices";
import calendarSummary from "../../graphql/bsl/gql-custom/queries/calendarSummary.gql";

function* getCalendarSummaryQuery(
  action: ReturnType<typeof getCalendarSummary>
) {
  const calendarSummaryCall = () =>
    apolloClient.query<{ calendarSummary: AvailableYear }>({
      query: calendarSummary,
      fetchPolicy: "no-cache",
    });

  try {
    const response = yield call(calendarSummaryCall);
    const { calendarSummary } = response.data!;
    if (calendarSummary) {
      yield put(getCalendarSummarySuccess(calendarSummary));
    } else {
      throw Error("Failed to get available dates");
    }
  } catch (error: any) {
    yield put(getCalendarSummaryFail(error.message));
  }
}

export default function* calendarSummarySagas() {
  yield all([
    takeLatest("calendarSummary/getCalendarSummary", getCalendarSummaryQuery),
  ]);
}

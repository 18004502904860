import {
  all,
  call,
  put,
  select,
  takeLatest,
  takeEvery,
} from "redux-saga/effects";
import { apolloClient } from "../../apollo-client";
import {
  AddExtraResponse,
  BasketAndAvailableExtrasResponseUnion,
  ExtraDetailsPageResponse,
  ExtraDetailsResponse,
  ExtrasStockAvailabilityResponseUnion,
  ExtraStockAvailabilityResponseUnion,
  MutationAddExtrasToCabinArgs,
  MutationRemoveExtraFromBasketArgs,
  QueryExtraDetailsArgs,
  QueryExtraDetailsPageUrlArgs,
  QueryExtrasForReservationArgs,
  QueryExtrasStockAvailabilityArgs,
  QueryExtraStockAvailabilityArgs,
} from "@generated/types";

import { RemoveExtraFromBasketResponse } from "../../graphql/bsl/gql-custom/types";
import { extraListSelector, loginSelector } from "../selectors";
import {
  getExtras,
  getExtrasFail,
  getExtrasSuccess,
  resetExtrasList,
  addExtra,
  addExtraFail,
  addExtraSuccess,
  viewExtraDetailsWithSticky,
  removeExtra,
  removeExtraFail,
  removeExtraSuccess,
  getExtraDetailsPageUrlFail,
  getExtraDetailsPageUrlSuccess,
  getExtraDetailsContent,
  getExtraDetailsContentFail,
  getExtraDetailsContentSuccess,
  getExtraStock,
  getExtraStockFail,
  getExtraStockSuccess,
  getExtrasStock,
  getExtrasStockFail,
  getExtrasStockSuccess,
} from "../slices";
import getExtrasQuery from "../../graphql/bsl/gql-custom/queries/extrasForReservation.gql";
import extraDetailsPageURLQuery from "../../graphql/bsl/gql-generated/dot-gql/queries/extraDetailsPageURL.gql";
import extraDetailsQuery from "../../graphql/bsl/gql-generated/dot-gql/queries/extraDetails.gql";
import addExtrasMutation from "../../graphql/bsl/gql-generated/dot-gql/mutations/addExtrasToCabin.gql";
import removeExtrasMutation from "../../graphql/bsl/gql-generated/dot-gql/mutations/removeExtraFromBasket.gql";
import extraStockAvailability from "../../graphql/bsl/gql-custom/queries/extraStockAvailability.gql";
import extrasStockAvailability from "../../graphql/bsl/gql-custom/queries/extrasStockAvailability.gql";
import { ExtrasForReservationArgs } from "src/graphql/bsl/gql-generated/custom-types";

// Extra List Sagas:
function* getExtrasSaga(action: ReturnType<typeof getExtras>) {
  const { data } = yield select(loginSelector);
  const token = data?.token;
  if (!!token) {
    const getExtrasCall = () =>
      apolloClient.query<
        { extrasForReservation: BasketAndAvailableExtrasResponseUnion },
        QueryExtrasForReservationArgs | ExtrasForReservationArgs
      >({
        query: getExtrasQuery,
        variables: action.payload.extrasQuery,
        context: {
          headers: {
            authorization: `Bearer ${token}`,
          },
          timeout: 120000, // it's a temparary timeout fix and will be removed after the bsl fix.
        },
        fetchPolicy: "no-cache",
      });

    try {
      yield put(
        resetExtrasList({
          filtered: action.payload.filtered,
          sortMethod: action.payload.sortMethod,
          isExtras: action.payload.isExtras || false,
        })
      );
      const getExtrasResponse = yield call(getExtrasCall);
      const { extrasForReservation } = getExtrasResponse.data!;

      switch (extrasForReservation?.__typename) {
        case "BasketAndAvailableExtrasResponse": {
          if (!!extrasForReservation?.basketAndAvailableExtras)
            yield put(
              getExtrasSuccess({
                extrasResponse: extrasForReservation.basketAndAvailableExtras,
                filtered: action.payload.filtered,
                sortMethod: action.payload.sortMethod,
                isExtras: action.payload.isExtras || false,
              })
            );
          break;
        }
        case "SystemError": {
          throw Error(
            extrasForReservation?.message || "Failed to remove attendee"
          );
          break;
        }
        default:
          break;
      }
    } catch (error: any) {
      yield put(getExtrasFail(error as any)); // todo: Add global type for errors. Errors here is an array
      // appInsights.trackException(error);
    }
  } else {
    const error: any = "Unable to get Extras due to missing token.";
    yield put(getExtrasFail(error));
    // appInsights.trackException(error);
  }
}

function* getExtrasWithStateArgsSaga() {
  const list = yield select(extraListSelector);

  if (list && list.getExtrasRequest) {
    yield put(
      getExtras({
        extrasQuery: list.getExtrasRequest,
        filtered: false,
      })
    );
  }
}

// Add Extra Sagas:
function* addExtraSaga(action: ReturnType<typeof addExtra>) {
  const addExtraCall = () =>
    apolloClient
      .mutate<
        { addExtrasToCabin: AddExtraResponse[] },
        MutationAddExtrasToCabinArgs
      >({
        mutation: addExtrasMutation,
        fetchPolicy: "no-cache",
        variables: {
          request: action.payload.addExtraInput,
        },
      })
      .then((response) => response.data?.addExtrasToCabin);

  try {
    const addExtraData: AddExtraResponse[] = yield call(addExtraCall);
    const isPostBookingJourney = action.payload.isPostBookingJourney;
    const [firstResult] = addExtraData!;

    if (firstResult.success) {
      yield put(
        addExtraSuccess({
          addExtraResponse: addExtraData as AddExtraResponse[],
          isPostBookingJourney,
        })
      );
      if (action.payload.showSticky) {
        yield put(
          viewExtraDetailsWithSticky(
            action.payload.addExtraInput[0].cabinReservationId
          )
        );
      }
    } else {
      throw Error(firstResult?.errorInfo || "Error adding extra.");
    }
  } catch (error: any) {
    yield put(addExtraFail(error.message)); // todo: Add global type for errors.
    //   appInsights.trackException(error);
  }
}

// Remove Extra Sagas:
function* removeExtraSaga(action: ReturnType<typeof removeExtra>) {
  const removeExtraCall = () =>
    apolloClient.mutate<
      { removeExtraFromBasket: RemoveExtraFromBasketResponse },
      MutationRemoveExtraFromBasketArgs
    >({
      mutation: removeExtrasMutation,
      fetchPolicy: "no-cache",
      variables: action.payload.mutationRemoveExtraFromBasketArgs,
    });

  try {
    const removeExtraResponse = yield call(removeExtraCall);
    const removeExtraData = removeExtraResponse?.data?.removeExtraFromBasket;

    if (removeExtraData) {
      yield put(
        removeExtraSuccess({
          removeExtraFromBasketResponse: removeExtraData,
          isPostBookingJourney: action.payload.isPostBookingJourney,
        })
      );
    } else {
      throw Error("Failed to remove extra");
    }
  } catch (error: any) {
    yield put(removeExtraFail(error.message));
    //   appInsights.trackException(error);
  }
}

// Extra details
function* getExtraDetailsPageUrl(action: any) {
  const getPageUrlCall = () =>
    apolloClient.query<
      { extraDetailsPageURL: ExtraDetailsPageResponse },
      QueryExtraDetailsPageUrlArgs
    >({
      query: extraDetailsPageURLQuery,
      variables: action.payload,
      fetchPolicy: "no-cache",
    });

  try {
    const getPageUrlResponse = yield call(getPageUrlCall);
    const pageUrl = getPageUrlResponse?.data?.extraDetailsPageURL?.url;

    if (!!pageUrl) {
      yield put(getExtraDetailsPageUrlSuccess(pageUrl));
    } else {
      yield put(getExtraDetailsPageUrlFail("Could not get page URL"));
    }
  } catch (error: any) {
    yield put(getExtraDetailsPageUrlFail(error.message));
  }
}

function* getExtraDetailsPageUrlSuccessSaga(action: any) {
  yield put(
    getExtraDetailsContent({
      extraDetailPageUrl: action.payload,
    })
  );
}

function* getExtraDetailsContentSaga(action: any) {
  const getExtraDetailsContentCall = () =>
    apolloClient.query<
      { extraDetails: ExtraDetailsResponse },
      QueryExtraDetailsArgs
    >({
      query: extraDetailsQuery,
      variables: action.payload,
      fetchPolicy: "no-cache",
    });

  try {
    const getExtraDetailsContent = yield call(getExtraDetailsContentCall);
    const data = getExtraDetailsContent?.data?.extraDetails;

    if (!!data?.extraDetails) {
      yield put(getExtraDetailsContentSuccess(data.extraDetails));
    } else {
      yield put(getExtraDetailsContentFail("Could not get extra details"));
    }
  } catch (error: any) {
    yield put(getExtraDetailsContentFail(error.message));
  }
}

// Extra Stock Availability
function* getExtraStockAvailability(action: ReturnType<typeof getExtraStock>) {
  const getExtrasStockAvailabilityCall = () =>
    apolloClient.query<
      { extraStockAvailability: ExtraStockAvailabilityResponseUnion },
      QueryExtraStockAvailabilityArgs
    >({
      query: extraStockAvailability,
      variables: action.payload,
      fetchPolicy: "no-cache",
    });

  try {
    const getExtrasStockResponse = yield call(getExtrasStockAvailabilityCall);
    const { data } = getExtrasStockResponse;

    switch (data?.extraStockAvailability.__typename) {
      case "ExtraStockAvailabilityResponse":
        yield put(getExtraStockSuccess(data.extraStockAvailability));
        break;
    }
  } catch (error: any) {
    yield put(getExtraStockFail(error.message));
    //   appInsights.trackException(error);
  }
}

// Extras Stock Availability
function* getExtrasStockAvailability(
  action: ReturnType<typeof getExtrasStock>
) {
  const getExtrasStockAvailabilityCall = () =>
    apolloClient.query<
      { extrasStockAvailability: ExtrasStockAvailabilityResponseUnion },
      QueryExtrasStockAvailabilityArgs
    >({
      query: extrasStockAvailability,
      variables: action.payload,
      fetchPolicy: "no-cache",
    });

  try {
    const getExtrasStockResponse = yield call(getExtrasStockAvailabilityCall);
    const { data } = getExtrasStockResponse;

    switch (data?.extrasStockAvailability.__typename) {
      case "ExtrasStockAvailabilityResponse":
        yield put(getExtrasStockSuccess(data.extrasStockAvailability));

        break;
    }
  } catch (error: any) {
    yield put(getExtrasStockFail(error.message));
    // appInsights.trackException(error);
  }
}

export default function* extraSagas() {
  yield all([
    takeLatest("extraList/getExtras", getExtrasSaga),
    takeLatest("extraList", getExtrasWithStateArgsSaga),
    takeEvery("extraAdd/addExtra", addExtraSaga),
    takeLatest("extraRemove/removeExtra", removeExtraSaga),
    takeLatest(
      "extraDetailsPage/getExtraDetailsPageUrl",
      getExtraDetailsPageUrl
    ),
    takeLatest(
      "extraDetailsPage/getExtraDetailsPageUrlSuccess",
      getExtraDetailsPageUrlSuccessSaga
    ),
    takeLatest(
      "extraDetailsContent/getExtraDetailsContent",
      getExtraDetailsContentSaga
    ),
    takeLatest("extraStockControl/getExtraStock", getExtraStockAvailability),
    takeLatest("extrasStock/getExtrasStock", getExtrasStockAvailability),
  ]);
}

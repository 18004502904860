import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { apolloClient } from "../../apollo-client";
import tokenisedCardsQuery from "../../graphql/bsl/gql-custom/queries/tokenisedCards.gql";
import applicableSavedCardsQuery from "../../graphql/bsl/gql-custom/queries/applicableTokenisedCards.gql";
import {
  QueryApplicableTokenisedCardsArgs,
  QueryTokenisedCardsArgs,
  TokenisedCardsResponse,
} from "@generated/types";
import { getErrorMessageByKey } from "../../components/Utils";
import { errorMessagesDataSelector } from "../selectors";
import {
  getPaymentCardsSuccess,
  getPaymentCardsFail,
  getPaymentCardsAction,
  getApplicableSavedCardsSuccess,
  getApplicableSavedCardsFail,
  getApplicableSavedCardsAction,
} from "../slices";

function* getPaymentCards(action: ReturnType<typeof getPaymentCardsAction>) {
  const getPaymentCardsCall = () =>
    apolloClient.query<
      { tokenisedCards: TokenisedCardsResponse },
      QueryTokenisedCardsArgs
    >({
      query: tokenisedCardsQuery,
      variables: action.payload,
      fetchPolicy: "no-cache",
    });
  try {
    const response = yield call(getPaymentCardsCall);
    if (response.data?.tokenisedCards) {
      yield put(getPaymentCardsSuccess(response.data.tokenisedCards));
    } else {
      throw Error("Failed to get payment cards");
    }
  } catch (error: any) {
    const errorMessages = yield select(errorMessagesDataSelector);
    const errorByKey = getErrorMessageByKey(error.message, errorMessages);
    yield put(getPaymentCardsFail(errorByKey?.message || error.message));
    // appInsights.trackException(error);
  }
}

function* getApplicableSavedCards(
  action: ReturnType<typeof getApplicableSavedCardsAction>
) {
  const getApplicableSavedCardsCall = () =>
    apolloClient.query<
      { applicableTokenisedCards: TokenisedCardsResponse },
      QueryApplicableTokenisedCardsArgs
    >({
      query: applicableSavedCardsQuery,
      variables: action.payload,
      fetchPolicy: "no-cache",
    });
  try {
    const response = yield call(getApplicableSavedCardsCall);

    if (response.data?.applicableTokenisedCards) {
      yield put(
        getApplicableSavedCardsSuccess(response.data.applicableTokenisedCards)
      );
    } else {
      throw Error("Failed to get payment cards");
    }
  } catch (error: any) {
    const errorMessages = yield select(errorMessagesDataSelector);
    const errorByKey = getErrorMessageByKey(error.message, errorMessages);
    yield put(
      getApplicableSavedCardsFail(errorByKey?.message || error.message)
    );
    // appInsights.trackException(error);
  }
}

export default function* paymentCardsSagas() {
  yield all([
    takeLatest("paymentCards/getPaymentCardsAction", getPaymentCards),
    takeLatest(
      "applicableSavedCards/getApplicableSavedCardsAction",
      getApplicableSavedCards
    ),
  ]);
}

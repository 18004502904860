import { AnyAction, combineReducers, configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import storageSession from "redux-persist/lib/storage/session";
import { persistReducer, persistStore } from "redux-persist";
import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import rootSaga from "../store/sagas/rootSagas";
import FeaturedExtraStockControlReducer from "./slices/featured-extra-stock-control";
import BookingSearchReducer from "./slices/booking-search.slice";
import BookingSummaryReducer from "./slices/booking-summary.slice";
import UpcomingBookingReducer from "./slices/booking-upcoming.slice";
import ExtraStockControlReducer from "./slices/extra-stock-control.slice";
import FeaturedExtraDetailsContentReducer from "./slices/featured-extra-details-content";
import FutureBookingsReducer from "./slices/future-bookings.slice";
import SearchLocationReducer from "./slices/location-search.slice";
import LocationReducer from "./slices/location.slice";
import PlanYourVisitReducer from "./slices/plan-your-visit.slice";
import ReservationReducer from "./slices/reservation.slice";
import SingleFutureCabinReservationReducer from "./slices/single-future-booking.slice";
import siteSettingsSlice from "./slices/site-settings.slice";
import StickyReducer from "./slices/sticky.slice";
import AddCabinReducer from "./slices/cabin-add.slice";
import SearchCabinReducer from "./slices/cabin-search.slice";
import CabinTypesReducer from "./slices/cabinsType";
import CabinTypesAtLocationReducer from "./slices/cabin-type-location.slice";
import CabinsForReservationReducer from "./slices/cabin-for-reservation.slice";
import AddSpecificCabinReducer from "./slices/cabin-specific-add.slice";
import RemoveSpecificCabinReducer from "./slices/cabin-specific-remove.slice";
import SwapSpecificCabinReducer from "./slices/cabin-specific-swap.slice";
import BookingPersistReducer from "./slices/persist-booking.slice";
import AddExtraReducer from "./slices/extra-add.slice";
import RemoveExtraReducer from "./slices/extra-remove.slice";
import LocationBlockInfoReducer from "./slices/location-block-info.slice";
import ExtraUiReducer from "./slices/extra-ui.slice";
import ExtraListReducer from "./slices/extra-list.slice";
import AddFeaturedExtraReducer from "./slices/featured-extras-add.slice";
import RemoveFeaturedExtraReducer from "./slices/featured-extra-remove";
import FeaturedExtraListReducer from "./slices/featured-extra-list.slice";
import ConfirmedBookingSummaryReducer from "./slices/confirmed-booking-summary.slice";
import ConfirmedBookingInfoReducer from "./slices/confirmed-booking-info.slice";
import ConfirmedBookingCabinEssentialsReducer from "./slices/confirmed-booking-cabin-essentials.slice";
import CreateExtraPaymentReducer from "./slices/extra-payment.slice";
import ExtraDetailsPageReducer from "./slices/extra-details-page.slice";
import ExtraDetailsContentReducer from "./slices/extra-details-content.slice";
import ExtrasPurchasedReducer from "./slices/extra-purchased.slice";
import LoginReducer from "./slices/auth.slice";
import AccountReducer from "./slices/account.slice";
import RegisterReducer from "./slices/register.slice";
import ResetPasswordReducer from "./slices/reset-password.slice";
import ForgotPasswordReducer from "./slices/forgot-password.slice";
import BreadcrumbReducer from "./slices/breadcrumb.slice";
import BookingCustomerAddReducer from "./slices/booking-customer-add.slice";
import locationMapReducer from "./slices/location-map.slice";
import ErrorMessagesReducer from "./slices/error-messages.slice";
import DatesReducer from "./slices/dates.slice";
import PromoCodeReducer from "./slices/promo-code.slice";
import createPaymentReducer from "./slices/payment-create.slice";
import faqsSlice from "./slices/faq.slice";
import ActiveStepperPersistReducer from "./slices/active-stepper-persisted.slice";
import GVbasketIdPersistReducer from "./slices/gv-basketId-persisted.slice";
import GVSettingsReducer from "./slices/gv-settings.slice";
import GiftVoucherAddReducer from "./slices/gift-voucher-add.slice";
import GiftVoucherBasketReducer from "./slices/gift-voucher-basket.slice";
import GiftVoucherConfirmedBasketReducer from "./slices/gift-voucher-confirmedBasket.slice";
import GiftVoucherEditReducer from "./slices/gift-voucher-edit.slice";
import GiftVoucherPaymentReducer from "./slices/gift-voucher-payment.slice";
import GiftVoucherRemoveReducer from "./slices/gift-voucher-remove.slice";
import blogsSlice from "./slices/blog.slice";
import PostCodeAddressesReducer from "./slices/post-code-addresses.slice";
import CreateConfirmFreeExtrasReducer from "./slices/confirm-freeextras.slice";
import voucherReducer from "./slices/voucher.slice";
import voucherApplyReducer from "./slices/voucher-apply.slice";
import voucherRemoveReducer from "./slices/voucher-remove.slice";
import bookingDataForGaReducer from "./slices/booking-data-for-ga.slice";
import changePasswordReducer from "./slices/change-password.slice";
import updateMydetailsReducer from "./slices/update-mydetails.slice";
import { featuredExtraReducer } from "./slices/featured-extra.slice";
import scheduledPaymentsByBookingIdReducer from "./slices/scheduled-payments-by-booking-id.slice";
import scheduledPaymentsByBookingReducer from "./slices/scheduled-payments-by-booking.slice";
import scheduledPaymentReducer from "./slices/scheduled-payment.slice";
import paymentCardsReducer from "./slices/payment-cards.slice";
import addCardReducer from "./slices/add-card.slice";
import removeCardReducer from "./slices/removeCard.slice";
import changeCardReducer from "./slices/change-card.slice";
import applicableSavedCardsReducer from "./slices/applicable-saved-cards.slice";
import abandonedBasketSlice from "./slices/abandoned-basket.slice";
import addAttendeeSlice from "./slices/attendee-add.slice";
import removeAttendeeSlice from "./slices/attendee-remove.slice";
import attendeeSummarySlice from "./slices/attendee-summary.slice";
import vehicleAddSlice from "./slices/vehicle-add.slice";
import vehicleRemoveSlice from "./slices/vehicle-remove.slice";
import extrasStockControlSlice from "./slices/extras-stock-control.slice";
import paymentOptionsSlice from "./slices/payment-options.slice";
import calendarSummarySlice from "./slices/calendar-summary.slice";
import authRememberMeSlice from "./slices/auth-remember-me.slice";
import cabinInfoSlice from "./slices/cabin-info.slice";
import reservationFromReferralSlice from "./slices/reservation-from-referral.slice";
import mandatoryExtrasSlice from "./slices/mandatory-extras.slice";

const authPersistConfig = {
  key: "login",
  storage: storageSession,
};

const rootReducer = combineReducers({
  account: AccountReducer,
  abandonedBasket: abandonedBasketSlice,
  cabinAdd: AddCabinReducer,
  cabinInfo: cabinInfoSlice,
  cabinSearch: SearchCabinReducer,
  cabinAllTypes: CabinTypesReducer,
  cabinTypesAtLocation: CabinTypesAtLocationReducer,
  cabinsForReservation: CabinsForReservationReducer,
  cabinSpecificAdd: AddSpecificCabinReducer,
  cabinSpecificRemove: RemoveSpecificCabinReducer,
  cabinSpecificSwap: SwapSpecificCabinReducer,
  bookingSearch: BookingSearchReducer,
  bookingSummary: BookingSummaryReducer,
  bookingUpcoming: UpcomingBookingReducer,
  bookingPersisted: BookingPersistReducer,
  futureBookings: FutureBookingsReducer,
  addExtra: AddExtraReducer,
  removeExtra: RemoveExtraReducer,
  extraUi: ExtraUiReducer,
  extraList: ExtraListReducer,
  addFeaturedExtra: AddFeaturedExtraReducer,
  removeFeaturedExtra: RemoveFeaturedExtraReducer,
  featuredExtra: featuredExtraReducer,
  featuredExtrasList: FeaturedExtraListReducer,
  locations: LocationReducer,
  locationBlockInfo: LocationBlockInfoReducer,
  locationsSearch: SearchLocationReducer,
  planYourVisit: PlanYourVisitReducer,
  confirmedBookingSummary: ConfirmedBookingSummaryReducer,
  confirmedBookingInfo: ConfirmedBookingInfoReducer,
  confirmedBookingCabinEssentials: ConfirmedBookingCabinEssentialsReducer,
  reservation: ReservationReducer,
  siteSettings: siteSettingsSlice,
  sticky: StickyReducer,
  extraPayment: CreateExtraPaymentReducer,
  extraDetailsPage: ExtraDetailsPageReducer,
  extraDetailsContent: ExtraDetailsContentReducer,
  featuredExtraDetails: FeaturedExtraDetailsContentReducer,
  extrasPurchased: ExtrasPurchasedReducer,
  extraStock: ExtraStockControlReducer,
  extrasStock: extrasStockControlSlice,
  featuredExtraStock: FeaturedExtraStockControlReducer,
  singleFutureCabinReservation: SingleFutureCabinReservationReducer,
  login: persistReducer(authPersistConfig, LoginReducer),
  register: RegisterReducer,
  resetPassword: ResetPasswordReducer,
  forgotPassword: ForgotPasswordReducer,
  breadcrumb: BreadcrumbReducer,
  bookingCustomerAdd: BookingCustomerAddReducer,
  locationMap: locationMapReducer,
  errorMessages: ErrorMessagesReducer,
  dates: DatesReducer,
  promoCode: PromoCodeReducer,
  createPayment: createPaymentReducer,
  faqs: faqsSlice,
  activeStepperSetting: ActiveStepperPersistReducer,
  gvBasketIdPersisted: GVbasketIdPersistReducer,
  gvSettings: GVSettingsReducer,
  giftVoucherAdd: GiftVoucherAddReducer,
  giftVoucherBasket: GiftVoucherBasketReducer,
  giftVoucherConfirmedBasket: GiftVoucherConfirmedBasketReducer,
  giftVouchersSaved: GiftVoucherEditReducer,
  giftVouchersPayment: GiftVoucherPaymentReducer,
  giftVoucherRemove: GiftVoucherRemoveReducer,
  blogs: blogsSlice,
  postCodeAddresses: PostCodeAddressesReducer,
  confirmFreeExtras: CreateConfirmFreeExtrasReducer,
  voucher: voucherReducer,
  voucherApply: voucherApplyReducer,
  voucherRemove: voucherRemoveReducer,
  bookingDataForGA: bookingDataForGaReducer,
  changePasswordCreate: changePasswordReducer,
  updateMyDetailsCreate: updateMydetailsReducer,
  scheduledPaymentsByBooking: scheduledPaymentsByBookingReducer,
  scheduledPaymentsByBookingId: scheduledPaymentsByBookingIdReducer,
  attendeeAdd: addAttendeeSlice,
  attendeeRemove: removeAttendeeSlice,
  attendeeSummary: attendeeSummarySlice,
  vehicleAdd: vehicleAddSlice,
  vehicleRemove: vehicleRemoveSlice,
  scheduledPayments: scheduledPaymentReducer,
  paymentCards: paymentCardsReducer,
  addCard: addCardReducer,
  removedCards: removeCardReducer,
  changeCard: changeCardReducer,
  applicableSavedCards: applicableSavedCardsReducer,
  paymentOptions: paymentOptionsSlice,
  calendarSummary: calendarSummarySlice,
  authRememberMe: authRememberMeSlice,
  reservationFromReferral: reservationFromReferralSlice,
  mandatoryExtras: mandatoryExtrasSlice,
});

const persistConfig = {
  key: "root",
  version: 1,
  storage: storageSession,
  whitelist: [
    "app",
    "login",
    "register",
    "bookingPersisted",
    "gvBasketIdPersisted",
    "bookingSearch",
    "siteSettings",
    "sticky",
    "reservation",
    "errorMessages",
    "extraList",
    "extrasPurchased",
    "activeStepperSetting",
    "voucher",
    "locations",
    "authRememberMe",
    "reservationFromReferral",
    "mandatoryExtras",
  ],
};

const reducer = (state: ReturnType<typeof rootReducer>, action: AnyAction) => {
  if (action.type === "appState/resetAppState") {
    localStorage.removeItem("persist:root");
    state = undefined;
  }
  return rootReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, reducer);
const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(sagaMiddleware),
  // devTools: process.env.NODE_ENV === "development" ? { name: "" } : false,
});
export const persistedStore = persistStore(store);

sagaMiddleware.run(rootSaga);

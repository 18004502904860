import {
  all,
  call,
  put,
  select,
  takeLatest,
  takeEvery,
} from "redux-saga/effects";
import { apolloClient } from "../../apollo-client";
import createExtraPayment from "../../graphql/bsl/gql-custom/mutations/makePaymentForExtras.gql";
import { MutationMakeCardPaymentArgs, PaymentResponse } from "@generated/types";
import { PaymentDataStatus } from "../../components/Utils";
import {
  bookingPersistedStateSelector,
  voucherStateSelector,
  extraListSelector,
} from "../selectors";
import {
  createExtraCardPaymentSuccess,
  createExtraCardPaymentFail,
  createExtraCardPayment,
  getBookingConfirmationAction,
  resetExtras,
  setExtras,
} from "../slices";

function* createCardPaymentSaga(
  action: ReturnType<typeof createExtraCardPayment>
) {
  const createPaymentCall = () =>
    apolloClient.mutate<
      { makePaymentForExtras: PaymentResponse },
      MutationMakeCardPaymentArgs
    >({
      mutation: createExtraPayment,
      variables: {
        paymentRequest: action.payload,
      },
      fetchPolicy: "no-cache",
    });

  yield put(resetExtras());

  try {
    const response = yield call(createPaymentCall);
    const { makePaymentForExtras } = response.data!;

    if (
      makePaymentForExtras?.success &&
      (makePaymentForExtras?.status === PaymentDataStatus.OK ||
        makePaymentForExtras?.status === PaymentDataStatus.THREE_D_AUTH ||
        makePaymentForExtras?.status === PaymentDataStatus.GVOK ||
        makePaymentForExtras?.status === PaymentDataStatus.SCOK ||
        makePaymentForExtras?.status === PaymentDataStatus.GVSCOK)
    ) {
      yield put(createExtraCardPaymentSuccess(makePaymentForExtras));
    } else {
      throw Error(
        makePaymentForExtras?.statusDetail ||
          makePaymentForExtras?.nonValidReason ||
          "Payment Failed"
      );
    }
  } catch (error) {
    yield put(createExtraCardPaymentFail(error.message));
  }
}

function* createCardPaymentSuccessSaga(
  action: ReturnType<typeof createExtraCardPaymentSuccess>
) {
  // todo: check why GA is failing
  const { bookingId } = yield select(bookingPersistedStateSelector);
  const { data } = yield select(extraListSelector);
  const { totalVoucherDiscount } = yield select(voucherStateSelector);
  const basketExtras = data?.basketExtras;

  yield all([
    put(getBookingConfirmationAction({ bookingId })),
    put(
      setExtras({
        purchasedExtras: basketExtras,
        purchasedExtrasTotalPrice: data?.obtainingQuoteTotal ?? 0,
        vouchersTotalPrice: totalVoucherDiscount,
      })
    ),
  ]);
}

export default function* extraPaymentSagas() {
  yield all([
    takeEvery("extraPayment/createExtraCardPayment", createCardPaymentSaga),
    takeLatest(
      "extraPayment/createExtraCardPaymentSuccess",
      createCardPaymentSuccessSaga
    ),
  ]);
}

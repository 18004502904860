import { call, put, takeLatest } from "redux-saga/effects";
import { apolloClient } from "../../apollo-client";
import { BreadcrumbsResponse } from "@generated/types";
import {
  getBreadcrumbFailAction,
  getBreadcrumbSuccessAction,
  getBreadcrumbAction,
} from "../slices";
import getBreadcrumbQuery from "../../graphql/bsl/gql-generated/dot-gql/queries/breadcrumbs.gql";

function* getBreadcrumbSaga(action: ReturnType<typeof getBreadcrumbAction>) {
  const getBreadcrumbCall = () =>
    apolloClient.query<{ breadcrumbs: BreadcrumbsResponse }>({
      query: getBreadcrumbQuery,
      variables: action.payload,
      fetchPolicy: "no-cache",
    });

  try {
    const getBreadcrumbResponse = yield call(getBreadcrumbCall);
    const { data } = getBreadcrumbResponse;
    switch (data?.breadcrumbs.__typename) {
      case "BreadcrumbsResponse":
        yield put(
          getBreadcrumbSuccessAction(
            data?.breadcrumbs as Required<BreadcrumbsResponse>
          )
        );
        break;
    }
  } catch (error) {
    yield put(
      getBreadcrumbFailAction(error?.message || "Failed to get breadcrumb")
    );
    // appInsights.trackException(error);
  }
}

export default function* breadcrumbSagas() {
  yield takeLatest("breadCrumb/getBreadcrumbAction", getBreadcrumbSaga);
}
